<template>
  <h2 class="mainTitle mb-11">Update-statistic</h2>
  <div >
    <el-button size="small" type="success" class="mb-2" @click="onSubmitLogin">upload to server</el-button>
    <el-upload
      class="upload-demo"
      action="https://jsonplaceholder.typicode.com/posts/"
      multiple
      :limit="1"
      :on-remove="handleRemove"
      :file-list="fileList"
      :before-upload="beforeAvatarUpload"
      accept=".csv"
    >
      <el-button size="small" type="primary">Click to upload</el-button>
      <template #tip>
        <div class="el-upload__tip">jpg/png files with a size less than 500kb</div>
      </template>
    </el-upload>
  </div>
  <h2 class="mainTitle mb-3 mt-15">Result</h2>
  <template v-if="getTableError">
    <p class="text mt-5 mb-5 text-danger">{{ getTableError }}</p>
  </template>
  <table class="table table-row-dashed table-row-gray-300 gy-7 " v-else-if="table.length">
    <thead>
    <tr class="fw-bolder fs-5 text-gray-800">
      <th>Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Group</th>
      <th>Course name</th>
      <th>Ref code</th>
      <th>Ref name</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in table" :key="item.id">
      <td>{{ item.name }}</td>
      <td>{{ item.email }}</td>
      <td>{{ item.phone }}</td>
      <td>{{ item.group }}</td>
      <td>{{ item.courseName }}</td>
      <td>{{ item.refCode }}</td>
      <td>{{ item.refName }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { Actions } from "../../../store/enums/StoreEnums";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Update-statistic",
  methods: {
    onSubmitLogin() {
      this.$store.dispatch(Actions.UPLOAD_FILES, this.fileList)
        .then(() => {
          this.table = this.$store.getters.getPartnersTable
        })
    },
    handleRemove() {
      this.fileList = []
    },
    beforeAvatarUpload(file){
      this.fileList = [{
        name: file.name,
        file: file
      }]
    },
  },
  setup() {
    const store = useStore();

    const getTableError = computed(() => {
      return  store.getters.getPartnersError
        ? store.getters.getPartnersError
        : ''
    })
    return {
      getTableError
    }
  },
  data() {
    return {
      fileList: [],
      table: []
    }
  },
};
</script>

<style >
.mainTitle{
  font-size: 30px;
  font-weight: 400;
}
.text{
  font-size: 16px;
}
.upload-demo label,
.upload-demo li i{
  left: 0;
  right: initial;
}
.upload-demo li{
  padding-left: 20px;
}
</style>